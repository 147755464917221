.sideBarBox {
  background: #064CDB;
  padding-left: 10px;
  width: 220px;
  position: fixed;
}
.sideBarBox .logoBox a {
  display: flex;
  justify-content: center;
}
.sideBarBox .logoBox a > img {
  height: 83px;
  width: 70%;
  -o-object-fit: contain;
     object-fit: contain;
}
.sideBarBox .menuBox ul li {
  display: flex;
  align-items: center;
}
.sideBarBox .menuBox ul li a {
  color: white;
  text-transform: uppercase;
  letter-spacing: 2px;
  line-height: 4;
  font-size: 13px;
}
.sideBarBox .menuBox ul svg {
  color: white;
  font-size: 22px;
  margin-right: 20px;
}

.sideBarBox {
  height: 100vh;
  overflow-y: scroll;
  position: fixed;
}

.scrollBoxSideBar {
  position: relative;
  background: #000;
}

.sideBarBox::-webkit-scrollbar-track {
  background-color: #064CDB;
}

.sideBarBox::-webkit-scrollbar {
  width: 5px;
}

.sideBarBox::-webkit-scrollbar-thumb {
  background-color: #0ae;
  background-image: -webkit-gradient(linear, 0 0, 0 100%, color-stop(0.5, rgba(255, 255, 255, 0.2)), color-stop(0.5, transparent), to(transparent));
}

#sideBarShow {
  width: 220px;
}

#sideBarHide {
  width: 60px;
}
#sideBarHide .menuBox ul li {
  justify-content: center;
}
#sideBarHide .menuBox ul li svg {
  font-size: 25px;
  margin-top: 10px;
  margin-bottom: 10px;
}
#sideBarHide .menuBox ul li a {
  display: none;
}

#scrollBoxSideBarShow {
  width: 250px;
}

#scrollBoxSideBarHide {
  width: 60px;
}

#showLogo {
  display: block;
}

#hideLogo {
  display: none;
}/*# sourceMappingURL=sideBar.css.map */