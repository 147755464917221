.addUserModalBox {
    position: fixed;
    width: 100%;
    height: 100vh;
    overflow-y: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(37, 36, 36, 0.397);
    top: 0;
    left: 0;
}

.addUserModalBox .addUserBox {
    width: 40%;
    background: white;
    padding: 20px 10px;
    border-radius: 15px;
}

@media only screen and (max-width:768px) {
    .addUserModalBox .addUserBox {
        width: 100% !important;
    }
}

.addUserModalBox .addUserBox .inneraddUserBox label {
    margin-top: 15px;
    margin-bottom: 8px;
}

.addUserModalBox .addUserBox .inneraddUserBox select {
    height: 37px;
    position: relative;
}

.addUserModalBox .addUserBox .inneraddUserBox input {
    width: 100%;
}

.addUserModalBox .addUserBox .addUserBtnBox {
    margin-top: 20px;
    display: flex;
    justify-content: flex-end;
}

.addUserModalBox .addUserBox .addUserBtnBox button:nth-child(1) {
    background: #fd5d93;
    color: white;
}

.addUserBtnBox button:nth-child(1) {
    background: #fd5d93;
    color: white;
}

.addUserModalBox .addUserBox .addUserBtnBox button {
    margin: 0 10px;
    padding: 10px 25px;
    border-radius: 5px;
    font-weight: 500;
    letter-spacing: 1px;
}

.addUserBtnBox button {
    margin: 0 10px;
    padding: 10px 25px;
    border-radius: 5px;
    font-weight: 500;
    letter-spacing: 1px;
}

/*# sourceMappingURL=addUserModal.css.map */