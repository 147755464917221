// COLORS ===========================================
@import 'colors';


.addUserModalBox{
    position: fixed;
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(37, 36, 36, 0.397);
    top: 0;
    left: 0;

    .addUserBox{
        width: 40%;
        background: white;
        padding: 20px 10px;
        border-radius: 15px;
        .inneraddUserBox{
            label{
                margin-top: 15px;
                margin-bottom: 8px;
            }
            select{
                height: 37px;
                position: relative;
            }
            input{
                width: 100%;
            }
        }
        .addUserBtnBox{
            margin-top: 20px;
            display: flex;
            justify-content: flex-end;
            button:nth-child(1){
                background: #fd5d93;
                color: $white;
            }
            button{
                margin: 0 10px;
                padding: 10px 25px;
                border-radius: 5px;
                font-weight: 500;
                letter-spacing: 1px;
            }
        }
    }
}