.container {
  margin-top: 50px;
}
.container h1 {
  margin-left: 100px;
}
.container h6 {
  color: grey;
  font-weight: 400;
  font-size: small;
  margin-left: 105px;
}

.formBox {
  margin: 20px;
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin-left: 100px;
}
.formBox label {
  color: gray;
  font-weight: 500;
  font-size: 0.9rem;
}
.formBox input {
  font-size: 13px;
}
.formBox input:hover {
  border-color: #2196f3;
}
.formBox .pwdBox {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.formBox .pwdBox .form-check {
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-left: 3px;
  margin-bottom: 20px;
}
.formBox a {
  color: #a2a5bd;
}
.formBox a:hover {
  color: #2196f3;
}
.formBox .form-check {
  margin-top: 20px;
}
.formBox .form-check .form-check-input {
  margin-right: 10px;
}
.formBox .signInBtn {
  width: 100px;
  background: #a2a5bd;
  border-color: transparent;
  color: black;
}
.formBox .signInBtn:hover {
  background: grey;
}

.register {
  display: flex;
  justify-content: left;
}

.login_image img {
  height: 500px;
  width: 100%;
}/*# sourceMappingURL=register.css.map */