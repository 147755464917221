@import url('https://fonts.googleapis.com/css2?family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap');

body{
    padding: 0;
    margin: 0;
}

h1{
    font-size: 25px;
    font-weight: 400;
    font-family: 'Ubuntu', sans-serif;
    margin: 0;
}
h2{
    font-size: 25px;
    font-weight: 400;
    font-family: 'Ubuntu', sans-serif;
    margin: 0;
}
h3{
    font-size: 25px;
    font-weight: 400;
    font-family: 'Ubuntu', sans-serif;
    margin: 0;
}
h4{
    font-size: 25px;
    font-weight: 400;
    font-family: 'Ubuntu', sans-serif;
    margin: 0;
}
h5{
    font-size: 25px;
    font-weight: 400;
    font-family: 'Ubuntu', sans-serif;
    margin: 0;
}
h6{
    font-size: 25px;
    font-weight: 400;
    font-family: 'Ubuntu', sans-serif;
    margin: 0;
}

ul{
    padding: 0 !important;
    margin: 0 !important;
}
li{
    padding: 0;
    margin: 0%;
    list-style: none;
    font-family: 'Ubuntu', sans-serif;
}
span{
    cursor: pointer;
    display: inline-block;
    font-family: 'Ubuntu', sans-serif;
}
img{
    cursor: pointer;
}
svg{
    cursor: pointer;
}
button{
    border: none;
    cursor: pointer;
}
a{
    display: inline-block;
    font-family: 'Ubuntu', sans-serif;
    font-weight: 400;
    cursor: pointer;
    text-decoration: none !important;
}
a:hover{
    text-decoration: none !important;
}
p{
    margin: 0;
    padding: 0;
    font-family: 'Ubuntu', sans-serif;
}
input{
    outline: none;
    font-family: 'Ubuntu', sans-serif;
}
input:focus{
    outline: none;
}
input::placeholder{
    font-family: 'Ubuntu', sans-serif;
}
/* PAGES CLASSES ============================= */
.allPages{
    display: flex;
    position: relative;
    width: 100%;
    overflow-x: clip;
}

.allPages .innerBox{
    display: flex;
    flex-direction: column;
    width: 100%;
}
.category-img{
    height: 60px !important;
}