
// COLORS ===========================================
@import 'colors';


// ALL CUSTOMER'S LIST SCSS  ========================================================

.userListBox{
    margin-top: 20px;
    box-shadow: 3px 6px 10px $shadowColor;
    border-radius: 18px;
    border: 2px solid $borderColor;

    .addUserBtn{
        background: $lightBlue;
        color: $white;
        margin-top: 30px;
        margin-left: 5px;
        padding: 10px 30px;
        border-radius: 5px;
        letter-spacing: 1px;
    }

    .innerUserListBox{
        display: flex;
        justify-content: space-between;
        margin-top: 20px;
        border-bottom: 2px solid $borderColor;
        padding-bottom: 10px;
        margin-bottom: 20px;
        .btnBox{
            display: flex;
            justify-content: space-around;
            button{
                padding: 5px 10px;
                margin: 0 5px;
                height: 35px;
                border-radius: 5px;
                background: $lightBlue;
                color: $white;
                letter-spacing: 1px;
            }
            a{
                border-radius: 5px;
                margin: 0 5px;
                padding: 5px 10px;
                height: 35px;
                background: $lightBlue;
                color: $white;
                letter-spacing: 1px;
            }
        }
        .searchBar {
            position: relative;
            margin-right: 10px;
            svg {
                position: absolute;
                left: 6px;
                top: 12px;
                font-size: 22px;
                color: $lightgreycolor;
            }
            input {
                letter-spacing: 1px;
                border: none;
                background: $inputBg;
                border-radius: 5px;
                padding: 10px 17px 10px 30px;
                &::placeholder {
                    color: $grey;
                    font-size: 14px;
                }
            }
        }
    }
    .actionsBtns{
        width: 100%;
        button{
            padding: 10px 6px;
            margin: 5px 5px;
            color: $white;
            border-radius: 5px;
        }
        .upadteBtn{
            background: #00f2c3;
        }
        .deleteBtn{
            background: #ec250d;
        }
        .updatePwdBtn{
            background: #fd5d93;
        }
    }
}