.userListBox {
  margin-top: 20px;
  box-shadow: 3px 6px 10px rgba(158, 158, 158, 0.5098039216);
  border-radius: 18px;
  border: 2px solid rgba(128, 128, 128, 0.3215686275);
}
.userListBox .addUserBtn {
  background: #2196f3;
  color: white;
  margin-top: 30px;
  margin-left: 5px;
  padding: 10px 30px;
  border-radius: 5px;
  letter-spacing: 1px;
}
.userListBox .innerUserListBox {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  border-bottom: 2px solid rgba(128, 128, 128, 0.3215686275);
  padding-bottom: 10px;
  margin-bottom: 20px;
}
.userListBox .innerUserListBox .btnBox {
  display: flex;
  justify-content: space-around;
}
.userListBox .innerUserListBox .btnBox button {
  padding: 5px 10px;
  margin: 0 5px;
  height: 35px;
  border-radius: 5px;
  background: #2196f3;
  color: white;
  letter-spacing: 1px;
}
.userListBox .innerUserListBox .btnBox a {
  border-radius: 5px;
  margin: 0 5px;
  padding: 5px 10px;
  height: 35px;
  background: #2196f3;
  color: white;
  letter-spacing: 1px;
}
.userListBox .innerUserListBox .searchBar {
  position: relative;
  margin-right: 10px;
}
.userListBox .innerUserListBox .searchBar svg {
  position: absolute;
  left: 6px;
  top: 12px;
  font-size: 22px;
  color: rgba(128, 128, 128, 0.3215686275);
}
.userListBox .innerUserListBox .searchBar input {
  letter-spacing: 1px;
  border: none;
  background: rgba(158, 158, 158, 0.1607843137);
  border-radius: 5px;
  padding: 10px 17px 10px 30px;
}
.userListBox .innerUserListBox .searchBar input::-moz-placeholder {
  color: grey;
  font-size: 14px;
}
.userListBox .innerUserListBox .searchBar input::placeholder {
  color: grey;
  font-size: 14px;
}
.userListBox .actionsBtns {
  width: 100%;
}
.userListBox .actionsBtns button {
  padding: 10px 6px;
  margin: 5px 5px;
  color: white;
  border-radius: 5px;
}
.userListBox .actionsBtns .upadteBtn {
  background: #00f2c3;
}
.userListBox .actionsBtns .deleteBtn {
  background: #ec250d;
}
.userListBox .actionsBtns .updatePwdBtn {
  background: #fd5d93;
}/*# sourceMappingURL=userList.css.map */


.create-book-modal .modal-content{
  width: 600px;
  margin: 0 auto;
}

.uploaded-image{
  margin-top: 20px;
    padding-left: 10px;
    width:max-content;
    background-color: lightgreen;
}
.input-file{
  margin-top: 20px;
}

.body-modal{
  height: 500px;
  overflow-y:auto ;
}

.image-show-modal{
  height: 60px;
  width: fit-content;
}

.container-text{
  display: flex;
  width: 90%; /* Adjust as needed */
  margin: 0 auto;
  padding: 20px;
}
.text-modal-cat p{
  margin: 0 0 10px;
  overflow: auto;
}

.text-modal-cat p::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.text-modal-cat p {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.image-container{
  display: flex;
  justify-content: center;
  align-items: center;
}
.book-listing{
  cursor: pointer;
  padding: 10px 0px;
  border-bottom: 0.5px solid lightgrey !important;
};

.book-listing:not(:last-child) {
  border-bottom: 1px solid black;
}
.book-listing:last-child {
  border-bottom: none !important;
}

.not-found-container{
  height: 445px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.not-found-container p{
  margin: 0px;
  font-size: 20px;
}
.btn-box{
  display: flex;
  justify-content: center;
  align-items: center;
}
.btn-width{
  width: 130px;
  height: 50px;
}

.language-selection-modal .modal-content{
  width:550px;
}
.select-trans-opt-text{
  margin-top: 15px;
    font-weight: 500;
}