.customersList {
  margin-top: 20px;
  box-shadow: 3px 6px 10px rgba(158, 158, 158, 0.5098039216);
  border-radius: 18px;
  border: 2px solid rgba(128, 128, 128, 0.3215686275);
}
.customersList .innerCusListBox {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  border-bottom: 2px solid rgba(128, 128, 128, 0.3215686275);
  padding-bottom: 10px;
  margin-bottom: 20px;
}
.customersList .innerCusListBox .btnBox {
  display: flex;
  justify-content: space-around;
}
.customersList .innerCusListBox .btnBox button {
  padding: 5px 10px;
  margin: 0 5px;
  height: 35px;
  border-radius: 5px;
  background: #2196f3;
  color: white;
  letter-spacing: 1px;
}
.customersList .innerCusListBox .btnBox a {
  border-radius: 5px;
  margin: 0 5px;
  padding: 5px 10px;
  height: 35px;
  background: #2196f3;
  color: white;
  letter-spacing: 1px;
}
.customersList .innerCusListBox .searchBar {
  position: relative;
  margin-right: 10px;
}
.customersList .innerCusListBox .searchBar svg {
  position: absolute;
  left: 6px;
  top: 12px;
  font-size: 22px;
  color: rgba(128, 128, 128, 0.3215686275);
}
.customersList .innerCusListBox .searchBar input {
  letter-spacing: 1px;
  border: none;
  background: rgba(158, 158, 158, 0.1607843137);
  border-radius: 5px;
  padding: 10px 17px 10px 30px;
}
.customersList .innerCusListBox .searchBar input::-moz-placeholder {
  color: grey;
  font-size: 14px;
}
.customersList .innerCusListBox .searchBar input::placeholder {
  color: grey;
  font-size: 14px;
}/*# sourceMappingURL=customersList.css.map */